
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { getShortUuid } from '@/libs/uuid'
import _ from 'lodash'
import EntityModelManger from '@/libs/entitymodel'
import showFieldLinkModal from './modal/fieldlink-modal'
import {
  showNewFieldConstraintModal,
  showModiFieldConstraintModal
} from './modal/field-constraint'
import fieldTypeDescripts, {
  FieldType,
  FieldTypeDescript
} from '@/libs/entitymodel/field-type-descript'

import generationTypes from '@/libs/entitymodel/generation-types'
import jpaInjectTypes, { JpaInjectType } from '@/libs/entitymodel/jpa-Inject'
import { FieldConstraint } from '@/libs/entitymodel/field-constraint'
import EntityField from '@/libs/entitymodel/entity-field'
import EntityLeftJoin from '@/libs/entitymodel/entity-leftjoin'
import Entity from '@/libs/entitymodel/entity'
import { getterLockOrgIds } from '@/store'
import EnumSelector from '@/components/selector/enum-selector.vue'
import EnumWidget from './enum-widget.vue'
/**
 * 选择中的字段数据
 */
interface ActiveFiledData {
  field: EntityField;
  entity: Entity;
}

/**
 * 字段面版
 */
@Component({
  components: { EnumSelector, EnumWidget },
  name: 'fieldPropertyPanel'
})
export default class FieldPropertyPanel extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {
        field: {
          uuid: getShortUuid(),
          name: 'newfiled' + new Date().valueOf(),
          title: 'newfiled',
          primaryKey: false,
          type: 'Integer',
          length: undefined,
          nullable: false,
          // 是否快照字段
          transient: false,
          // 是否为联结字段
          linkField: false,
          // 联结字段uuid
          joinFieldUuid: undefined,
          // 联结对象uuid
          joinUuid: undefined
        },
        entity: undefined
      }
    }
  })
  value!: ActiveFiledData

  /// 当前值
  curValue: EntityField = {
    uuid: getShortUuid(),
    name: 'newfiled' + new Date().valueOf(),
    title: 'newfiled',
    primaryKey: false,
    type: 'Integer',
    length: undefined,
    nullable: false,
    // 是否快照字段
    transient: false,
    // 联结字段uuid
    joinFieldUuid: undefined,
    // 联结对象uuid
    joinUuid: undefined,
    // 是否为联结字段
    linkField: false
  }

  @Watch('value')
  watchValue (value: ActiveFiledData) {
    this.curValue = value.field
    this.entity = value.entity
    this.joins = this.entity.joins ? this.entity.joins : []

    this.setedLength = !!this.curValue.length
    this.setedScale = !!this.curValue.scale
    this.setedPercision = !!this.curValue.precision

    this.setJpaInject = !this.curValue.generatedValue

    // 初始化不能为空的字段
    if (!this.curValue.fieldContraintes) {
      this.curValue.fieldContraintes = []
    }

    // 设置当前字段类型的描述
    this.fieldTypeChange(this.curValue.type)

    this.setLinkEntityName()
    this.setLinkFieldName()
    this.readOnly =
      this.lockedOrgIds.findIndex(item => item === this.entity.orgId) < 0
  }

  @Watch('curValue.name', { immediate: true })
  onNameChanged (newName: string, oldName: string) {
    if (!newName || newName === '' || !/^[a-zA-z].*/.test(newName)) {
      this.curValue.name = oldName.charAt(0).toLowerCase() + oldName.slice(1)
    } else {
      this.curValue.name = newName.charAt(0).toLowerCase() + newName.slice(1)
    }
  }

  // 关联实体
  entity!: Entity

  /**
   * 当前是否只读
   */
  /**
   * 当前是否只读
   */
  readOnly = true

  // 关联的joins
  joins: EntityLeftJoin[] = []

  // 实体模型对象
  @Prop({
    type: Object,
    required: true
  })
  entityModelManger!: EntityModelManger

  /// 当前打开的面版
  openPanel: string[] = []

  linkEntityName = ''
  linkFieldName = ''

  // 已经设置了长度
  setedLength = false
  // 已经设置了精度
  setedScale = false
  // 已经设置了小数点
  setedPercision = false
  // 已经设置JPA注入
  setJpaInject = false

  /// 当前字段类型描述
  typeDescript: FieldTypeDescript = {
    title: '',
    fullName: '',
    canSetLength: true,
    canSetPrecision: true,
    canSetScale: true
  }

  // 自动赋值列表
  get generationTypes () {
    const res = []
    for (const key in generationTypes) {
      res.push(key)
    }
    return res
  }

  /// 当前是否需要设置generation
  get wantInputGeneration () {
    if (this.curValue.strategyType) {
      const descript = generationTypes[this.curValue.strategyType]
      return !!descript.wantGenerator
    }
    return false
  }

  // 自动注入类型
  get jpaInjectTypes () {
    const res = []
    for (const key in jpaInjectTypes) {
      res.push(key)
    }
    return res
  }

  // 当前类型允许的注入类型
  curCanJpaInjectTypes: JpaInjectType[] = []

  /// 联结实体名称
  setLinkEntityName () {
    if (!this.curValue.linkField || !this.curValue.joinUuid) {
      this.linkEntityName = ''
      return
    }
    const joins = this.entity.joins.filter(
      item => item.uuid === this.curValue.joinUuid
    )
    if (joins.length <= 0) {
      this.linkEntityName = ''
      return
    }
    const join = joins[0]
    const entity = this.entityModelManger.entitiesMap[join.joinEntityUuid]
    if (!entity) this.linkEntityName = ''
    this.linkEntityName = `${entity.title}(${entity.name})(alias:${join.joinAlias})`
  }

  /// 联结字段名称
  setLinkFieldName () {
    if (!this.curValue.linkField || !this.curValue.joinFieldUuid) {
      this.linkFieldName = ''
      return
    }
    const joins = this.entity.joins.filter(
      item => item.uuid === this.curValue.joinUuid
    )
    if (joins.length <= 0) {
      this.linkFieldName = ''
      return
    }
    const join = joins[0]
    const entity = this.entityModelManger.entitiesMap[join.joinEntityUuid]
    if (!entity) this.linkFieldName = ''
    const fields = this.entityModelManger
      .getEntityAllFieldes(entity.uuid)
      .filter(item => item.uuid === this.curValue.joinFieldUuid)
    if (fields.length <= 0) this.linkFieldName = ''
    const field = fields[0]
    this.linkFieldName = `${field.title}(${field.name})`
  }

  /// 🔗字段类型
  changeTypeToLinkField () {
    if (!this.curValue.linkField || !this.curValue.joinFieldUuid) {
      return
    }
    const joins = this.entity.joins.filter(
      item => item.uuid === this.curValue.joinUuid
    )
    if (joins.length <= 0) {
      this.linkFieldName = ''
      return
    }
    const join = joins[0]
    const entity = this.entityModelManger.entitiesMap[join.joinEntityUuid]
    if (!entity) this.linkFieldName = ''
    const fields = this.entityModelManger
      .getEntityAllFieldes(entity.uuid)
      .filter(item => item.uuid === this.curValue.joinFieldUuid)
    if (fields.length <= 0) this.linkFieldName = ''
    const field = fields[0]
    this.$set(this.curValue, 'type', field.type)
    if (field.type === 'Enum') {
      this.$set(this.curValue, 'enumUuid', field.enumUuid)
    }
    console.log('类型更新', field.type)
  }

  // 联结字段操作
  linkField () {
    showFieldLinkModal(
      this.curValue.joinUuid,
      this.curValue.joinFieldUuid,
      this.entity,
      this.entityModelManger,
      data => {
        return new Promise<void>((resolve, reject) => {
          this.curValue.joinUuid = data.joinUuid
          this.curValue.joinFieldUuid = data.linkFieldUuid

          this.$set(this.curValue, 'linkField', true)
          this.$set(this.curValue, 'transient', true)

          this.setLinkEntityName()
          this.setLinkFieldName()
          this.changeTypeToLinkField()
          resolve()
        })
      }
    )
  }

  /// 清除联结
  clearLinkField () {
    if (this.readOnly) return

    this.$Modal.confirm({
      title: '确认',
      content: '确认要删除字段关联吗？',
      onOk: () => {
        this.curValue.joinUuid = undefined
        this.curValue.joinFieldUuid = undefined

        this.$set(this.curValue, 'linkField', false)
        this.$set(this.curValue, 'transient', false)
        this.setLinkEntityName()
        this.setLinkFieldName()
      }
    })
  }

  /**
   * 取得当前工程的锁定组织
   */
  get lockedOrgIds () {
    return getterLockOrgIds()
  }

  /// 可选数据类型
  get fieldTypeNames () {
    const types = []
    for (const item in fieldTypeDescripts) {
      types.push(item)
    }
    return types
  }

  /// 字段类型变更事件
  fieldTypeChange (selectOption: any) {
    this.typeDescript = fieldTypeDescripts[selectOption as FieldType]

    // 如果选择的字段不允许为空
    if (!this.typeDescript.canSetNullable) {
      this.curValue.nullable = false
    }
    if (!this.typeDescript.canSetLength) {
      this.curValue.length = undefined
    }
    if (!this.typeDescript.canSetPrecision) {
      this.curValue.precision = undefined
    }
    if (!this.typeDescript.canSetScale) {
      this.curValue.scale = undefined
    }

    // 根据当前字段类型修改允许的jpa注入类型
    if (!this.curValue.type) {
      this.curCanJpaInjectTypes = []
    } else {
      const arr: JpaInjectType[] = []
      for (const key in jpaInjectTypes) {
        const cans = jpaInjectTypes[key as JpaInjectType].OnlyType
        const index = cans.findIndex(item => item === this.curValue.type)
        if (index >= 0) arr.push(key as JpaInjectType)
      }
      this.curCanJpaInjectTypes = arr
    }
    if (this.curCanJpaInjectTypes.length === 0) this.curValue.jpaInject = false
  }

  // 启用精度
  setedPercisionChange (value: boolean) {
    if (!value) {
      this.curValue.precision = undefined
    } else if (!this.curValue.precision) {
      this.curValue.precision = 1
    }
  }

  // 启用小数点
  setedScareChange (value: boolean) {
    if (!value) {
      this.curValue.scale = undefined
    } else if (!this.curValue.scale) {
      this.curValue.scale = 1
    }
  }

  // 启用长度
  setedLengthChange (value: boolean) {
    if (!value) {
      this.curValue.length = undefined
    } else if (!this.curValue.length) {
      this.curValue.length = 1
    }
  }

  // 启用自动赋值
  setedGeneratedValueChange (value: boolean) {
    if (!value) {
      this.curValue.generatedValue = undefined
    } else if (!this.curValue.strategyType) {
      this.$set(this.curValue, 'strategyType', 'IDENTITY')
    }

    // 与注入值进行排斥
    if (value) {
      this.curValue.jpaInject = false
    }
  }

  // 启用jpa注入
  setedJapInjectChange (value: boolean) {
    if (!value) {
      this.curValue.jpaInJectType = undefined
    } else if (!this.curValue.jpaInJectType) {
      this.$set(this.curValue, 'jpaInJectType', 'CreatedBy')
    }
    // 与生成值进行排斥
    if (value) {
      this.curValue.generatedValue = false
    }
  }

  // 取得jpa注入的标题
  getJpaInjectTitle (key: JpaInjectType) {
    return jpaInjectTypes[key]?.title
  }

  // 添加新的字段约束
  addNewFieldConstraint () {
    if (this.readOnly) return
    const that = this
    showNewFieldConstraintModal(
      this.curValue.type,
      this.curValue.title,
      this.curValue.fieldContraintes,
      data => {
        return new Promise<void>((resolve, reject) => {
          const arr = that.curValue.fieldContraintes || []
          arr.push(data)
          that.$set(this.curValue, 'fieldContraintes', arr)
          that.$forceUpdate()
          resolve()
        })
      }
    )
  }

  /// 取得字段约束的消息语言列表
  getFieldConstraintMessageLanguages (item: FieldConstraint): string[] {
    if (!item) return []
    const res = []
    for (const key in item.messages) {
      res.push(key)
    }
    return res
  }

  /// 编辑字段约束
  editFieldConstraint (index: number, item: FieldConstraint) {
    if (this.readOnly) return
    const that = this
    showModiFieldConstraintModal(
      this.curValue.type,
      this.curValue.title,
      item,
      data => {
        return new Promise<void>((resolve, reject) => {
          this.curValue.fieldContraintes![index] = data
          that.$forceUpdate()
          resolve()
        })
      }
    )
  }

  /// 删除字段约束
  delFieldConstraint (index: number) {
    if (this.readOnly) return
    const that = this
    this.$Modal.confirm({
      title: '删除数据校验？',
      content: '确认要删除数据校验吗?',
      onOk: () => {
        that.curValue.fieldContraintes?.splice(index, 1)
        that.$forceUpdate()
      }
    })
  }

  get fieldContraintes () {
    return this.curValue.fieldContraintes || []
  }

  created () {
    this.watchValue(this.value)
  }

  @Watch('curValue.uuid', { immediate: true })
  onCurValueUuidChange () {
    if (this.curValue.linkField) {
      this.openPanel = ['fieldLink']
    } else {
      this.openPanel = []
    }
  }
}
