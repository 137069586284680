
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import PropertyModal from './property-modal.vue'
import { expect } from 'chai'
import { BaseModal } from '@/libs/modal-helper'
import { Form } from 'view-design'
import _ from 'lodash'
import fieldConstraintTypes, {
  FieldConstraint,
  FieldConstraintType,
  getFieldConstraintTypesByFieldType,
  fieldConstraintValueTypes,
  FieldConstraintValueType,
  getDefaultFieldConstraint
} from '@/libs/entitymodel/field-constraint'
import { FieldType } from '@/libs/entitymodel/field-type-descript'
import { getterProject } from '@/store'

/**
 * 约束对话框
 */
@Component({
  name: 'fieldConstraintModal',
  components: { PropertyModal }
})
export default class FieldConstraintModal extends Vue implements BaseModal {
  @Prop({
    type: Object,
    required: true
  })
  value!: FieldConstraint

  @Prop({
    type: String,
    required: true
  })
  fieldType!: FieldType

  /// 字段名称
  @Prop({
    type: String,
    required: true
  })
  fieldName!: string

  @Prop({
    type: Array,
    default: () => [],
    required: false
  })
  readonly selectedKeys!: FieldConstraint[]

  /// 当前值
  curValue: FieldConstraint = this.value

  selectableFieldConstraintTypes!: string[]

  /// 筛选可供选择的字段约束类型
  filterSeleableType () {
    const selected = this.selectedKeys.map(e => e.type)
    return this.fieldConstraintTypes.filter(e => !selected.includes(e))
  }

  /// 字段约束的描述
  get descript () {
    return fieldConstraintTypes[this.curValue.type]
  }

  /// 字段支持的约束
  get fieldConstraintTypes () {
    return getFieldConstraintTypesByFieldType(this.fieldType)
  }

  /// 字段约束的值类型
  get fieldConstraintValueType (): FieldConstraintValueType {
    if (this.curValue.type === 'Length') return 'Number'
    return fieldConstraintValueTypes[this.fieldType]
  }

  /// 字段线束类型校验
  typerule = { required: true, message: '约束类型不能为空', trigger: 'blur' }

  /// 取得指定索引位的约束
  getValueRuleByIndex (index: number) {
    if (this.descript.validator) {
      return this.descript.validator[index]
    }
    return undefined
  }

  /// 变更约束类型
  changeType (value: FieldConstraintType) {
    this.curValue.values = getDefaultFieldConstraint(this.fieldType, value)
    const messages = fieldConstraintTypes[value].defaultMessages
      ? _.cloneDeep(fieldConstraintTypes[value]!.defaultMessages!)
      : {}

    for (const lg in messages) {
      const msg = messages[lg]
      messages[lg] = msg.replaceAll('{fieldname}', this.fieldName)
    }

    this.curValue.messages = messages
  }

  /// 当前支持的语言列表
  get languages () {
    const projectInfo = getterProject()

    return projectInfo?.languages || []
  }

  show (): void {
    ;(this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as PropertyModal).close()
  }

  /// 保存处理
  doSave () {
    const form = this.$refs.form as Form

    form.validate(result => {
      if (result && this.curValue) {
        this.$emit('onOk', this.curValue)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  /// 取消处理
  doCancel () {
    this.$emit('onCancel')
  }

  created () {
    this.selectableFieldConstraintTypes = this.filterSeleableType()
  }
}
