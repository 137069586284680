import fieldConstraintTypes, { FieldConstraint } from '@/libs/entitymodel/field-constraint'
import { FieldType } from '@/libs/entitymodel/field-type-descript'
import { showModal } from '@/libs/modal-helper'
import { getterProject } from '@/store'
import _ from 'lodash'
import modal from './field-constraint.vue'

/**
 * 弹出新增约束对话框
 * @param parent
 * @param onOk
 */
export function showNewFieldConstraintModal (type: FieldType, fieldName: string, selectedKeys?: FieldConstraint[],
  onOk?: (data: FieldConstraint) => Promise<void>) {
  const languages = getterProject()?.languages || []

  const descript = fieldConstraintTypes.NotNull

  const messages = descript.defaultMessages ? _.cloneDeep(descript.defaultMessages) : {}

  for (const lg in messages) {
    const msg = messages[lg]
    messages[lg] = msg.replaceAll('{fieldname}', fieldName)
  }

  /// 如果默认的提示消息中没有对应的语言，则添加
  for (const key of languages) {
    if (!messages[key]) {
      messages[key] = 'NotNull'
    }
  }

  showModal<FieldConstraint>(modal, {
    props: {
      value: {
        type: 'NotNull',
        values: [],
        messages: messages
      },
      fieldType: type,
      fieldName: fieldName,
      selectedKeys
    }
  }, true, onOk, undefined)
}

/**
 * 弹出编辑约束对话框
 * @param parent
 * @param onOk
 */
export function showModiFieldConstraintModal (type: FieldType, fieldName: string, item: FieldConstraint,
  onOk?: (data: FieldConstraint) => Promise<void>) {
  const languages = getterProject()?.languages || []

  /// 如果默认的提示消息中没有对应的语言，则添加
  for (const key of languages) {
    if (!item.messages[key]) {
      item.messages[key] = item.type
    }
  }

  showModal<FieldConstraint>(modal, {
    props: {
      value: item,
      fieldName: fieldName,
      fieldType: type
    }
  }, true, onOk, undefined)
}
