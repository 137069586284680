import { render, staticRenderFns } from "./field-property-panel.vue?vue&type=template&id=08e03a8d&scoped=true&"
import script from "./field-property-panel.vue?vue&type=script&lang=ts&"
export * from "./field-property-panel.vue?vue&type=script&lang=ts&"
import style0 from "./field-property-panel.vue?vue&type=style&index=0&id=08e03a8d&prod&lang=less&scoped=true&"
import style1 from "./field-property-panel.vue?vue&type=style&index=1&id=08e03a8d&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e03a8d",
  null
  
)

export default component.exports