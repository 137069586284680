import _ from 'lodash'
import { FieldType } from './field-type-descript'

// 字段约束定义
export type FieldConstraintType = 'NotBlank' | 'NotNull' |
  'Max' | 'Min' | 'Size' | 'Pattern' | 'Length' | 'Null' | 'DecimalMin' | 'DecimalMax' | 'Past' | 'Future' | 'Email' | 'Range' | 'Phone'

/// 字段约束
export interface FieldConstraint {
  /// 约束类型
  type: FieldConstraintType;
  messages: Record<string, string>;
  values: any[];
}

/// 字段约束的定义
export interface FieldConstraintTypeDescript {
  /// 标题
  titile: string;

  /// 值的个数
  valueCount: number;

  /// 值的标题
  valueTitiles: string[];

  /// 限定的值的范围
  onlyType: FieldType[];
  // 较验方法
  validator?: Array<any>;
  // 所有值的综合校验
  validatorAll?: (rule: any, value: Array<any> | undefined, callback: any) => void;
  // 默认值
  defalutValues?: any[];
  // 默认提示消息
  defaultMessages?: Record<string, string>;
}

/// 字段类型描述定义
const fieldConstraintTypes: Record<FieldConstraintType, FieldConstraintTypeDescript> = {
  /* NotEmpty: {
    title: '集合非空',
    valueCount: 0,
    valueTitiles: [],
    onlyType: ['']
  }, */
  NotBlank: {
    titile: '字串非空',
    valueCount: 0,
    valueTitiles: [],
    onlyType: ['String'],
    defaultMessages: {
      cn: '{fieldname}不允许为空',
      en: '{fieldname} Empty is not allowed'
    }
  },
  NotNull: {
    titile: '非空',
    valueCount: 0,
    valueTitiles: [],
    onlyType: [],
    defaultMessages: {
      cn: '{fieldname}不允许为空',
      en: '{fieldname} Null is not allowed'
    }
  },
  Max: {
    titile: '最大值',
    valueCount: 1,
    valueTitiles: ['最大值'],
    onlyType: ['Byte', 'BigDecimal', 'Double',
      'Float', 'Integer', 'Long', 'Short', 'byte',
      'double', 'float', 'int', 'long', 'short'],
    validator: [{ required: true, type: 'number', message: '最大值不允许为空', trigger: 'blur' }],
    defalutValues: [0],
    defaultMessages: {
      cn: '{fieldname}不能超过最大值',
      en: '{fieldname} can\'t exceed the maximum'
    }
  },
  Min: {
    titile: '最小值',
    valueCount: 1,
    valueTitiles: ['最小值'],
    onlyType: ['Byte', 'BigDecimal', 'Double',
      'Float', 'Integer', 'Long', 'Short', 'byte',
      'double', 'float', 'int', 'long', 'short'],

    validator: [{ required: true, type: 'number', message: '最小值不允许为空', trigger: 'blur' }],
    defalutValues: [0],
    defaultMessages: {
      cn: '{fieldname}不能超过最小值',
      en: '{fieldname} can\'t exceed the minimum'
    }
  },
  Size: {
    titile: '限定大小',
    valueCount: 2,
    valueTitiles: ['最小值', '最大值'],
    onlyType: ['Byte', 'BigDecimal', 'Double',
      'Float', 'Integer', 'Long', 'Short', 'byte',
      'double', 'float', 'int', 'long', 'short'],

    validator: [
      { required: true, type: 'number', message: '最小值不允许为空', trigger: 'blur' },
      { required: true, type: 'number', message: '最大值不允许为空', trigger: 'blur' }
    ],
    validatorAll: (rule: any, value: Array<any> | undefined, callback: any) => {
      if (!value || value.length !== 2) {
        callback(new Error('没有同时设置最大最小值'))
        return
      }
      if (value[0] > value[1]) {
        callback(new Error('最小值不能大于最大值'))
        return
      }
      callback()
    },
    defalutValues: [0, 1],
    defaultMessages: {
      cn: '{fieldname} 只允许范围内的值',
      en: '{fieldname} Only values in the range are allowed'
    }
  },
  Pattern: {
    titile: '正规表达式',
    valueCount: 1,
    valueTitiles: ['表达式'],
    onlyType: ['String'],
    validator: [
      { required: true, type: 'string', message: '表达式不允许为空', trigger: 'blur' }
    ],
    defalutValues: [''],
    defaultMessages: {
      cn: '{fieldname}表达式不允许为空',
      en: '{fieldname} Pattern are not allowed to be null'
    }
  },
  Length: {
    titile: '长度限定',
    valueCount: 1,
    valueTitiles: ['最大长度'],
    onlyType: ['String'],
    validator: [
      { required: true, type: 'number', message: '最大长度不能为空', trigger: 'blur' }
    ],
    defalutValues: [100],
    defaultMessages: {
      cn: '{fieldname}不能超过长度限定',
      en: '{fieldname} Do not exceed the length limit'
    }
  },
  Null: {
    titile: '只允许空',
    valueCount: 0,
    valueTitiles: [],
    onlyType: [],
    defaultMessages: {
      cn: '{fieldname}只允许空',
      en: '{fieldname} Only allow null'
    }
  },
  DecimalMin: {
    titile: '不小于可等于',
    valueCount: 1,
    valueTitiles: ['最小值'],
    onlyType: ['Byte', 'BigDecimal', 'Double',
      'Float', 'Integer', 'Long', 'Short', 'byte',
      'double', 'float', 'int', 'long', 'short'],
    validator: [
      { required: true, type: 'number', message: '最小值不能为空', trigger: 'blur' }
    ],
    defalutValues: [0],
    defaultMessages: {
      cn: '{fieldname}不可大于',
      en: '{fieldname} Not more than'
    }
  },
  DecimalMax: {
    titile: '不大于可等于',
    valueCount: 1,
    valueTitiles: ['最大值'],
    onlyType: ['Byte', 'BigDecimal', 'Double',
      'Float', 'Integer', 'Long', 'Short', 'byte',
      'double', 'float', 'int', 'long', 'short'],
    validator: [
      { required: true, type: 'number', message: '最大值不能为空', trigger: 'blur' }
    ],
    defalutValues: [1],
    defaultMessages: {
      cn: '{fieldname}不可小于',
      en: '{fieldname} Not less than'
    }
  },
  Past: {
    titile: '晚于当前',
    valueCount: 0,
    valueTitiles: [],
    onlyType: ['LocalDate', 'LocalDateTime'],
    defaultMessages: {
      cn: '{fieldname}不晚于当前',
      en: '{fieldname} not earlier than the current'
    }
  },
  Future: {
    titile: '早于当前',
    valueCount: 0,
    valueTitiles: [],
    onlyType: ['LocalDate', 'LocalDateTime'],
    defaultMessages: {
      cn: '{fieldname}不早于当前',
      en: '{fieldname} not Later than the current'
    }
  },
  Email: {
    titile: 'email格式',
    valueCount: 0,
    valueTitiles: [],
    onlyType: ['String'],
    validator: [
      { required: true, type: 'string', message: '不能为空', trigger: 'blur' }
    ],
    defalutValues: ['xxx@xxx'],
    defaultMessages: {
      cn: '{fieldname}不是合法的email格式',
      en: '{fieldname} Not a valid email format'
    }
  },
  Phone: {
    titile: '电话格式',
    valueCount: 0,
    valueTitiles: [],
    onlyType: ['String'],
    validator: [
      { required: true, type: 'string', message: '不能为空', trigger: 'blur' }
    ],
    defalutValues: ['13xxxxxxx'],
    defaultMessages: {
      cn: '{fieldname}不是合法的电话格式',
      en: '{fieldname} Not a valid phonenumber format'
    }
  },
  Range: {
    titile: '范围内',
    valueCount: 2,
    valueTitiles: ['最小值', '最大值'],
    onlyType: ['Byte', 'BigDecimal', 'Double',
      'Float', 'Integer', 'Long', 'Short', 'byte',
      'double', 'float', 'int', 'long', 'short', 'LocalDate', 'LocalDateTime'],

    validator: [
      { required: true, type: 'number', message: '最小值不能为空', trigger: 'blur' },
      { required: true, type: 'number', message: '最大值不能为空', trigger: 'blur' }
    ],
    validatorAll: (rule: any, value: Array<any> | undefined, callback: any) => {
      if (!value || value.length !== 2) {
        callback(new Error('没有同时设置最大最小值'))
        return
      }
      if (value[0] > value[1]) {
        callback(new Error('最小值不能大于最大值'))
        return
      }
      callback()
    },
    defalutValues: [0, 1],
    defaultMessages: {
      cn: '{fieldname}超出充许范围',
      en: '{fieldname} Beyond the allowable range'
    }
  }
}

export default fieldConstraintTypes

/// 字段约束值的类型
export type FieldConstraintValueType = 'String' | 'Number' | 'Date' | 'Time' | 'Boolean' | 'Float'

/// 不现类型的约束值的类型
export const fieldConstraintValueTypes: Record<FieldType, FieldConstraintValueType> = {
  // All: 'String',
  Enum: 'Number',
  Integer: 'Number',
  int: 'Number',
  Long: 'Number',
  long: 'Number',
  Short: 'Number',
  short: 'Number',
  Byte: 'Number',
  byte: 'Number',
  LocalDate: 'Date',
  LocalDateTime: 'Time',
  Double: 'Float',
  double: 'Float',
  Float: 'Float',
  float: 'Float',
  String: 'String',
  Boolean: 'Boolean',
  boolean: 'Boolean',
  Char: 'Number',
  char: 'Number',
  BigDecimal: 'Float'
}

/**
 * 通过字段类型取得默认的字段约束
 * @param type
 */
export function getDefaultFieldConstraint (type: FieldType, constraintType: FieldConstraintType): any[] {
  const descript = fieldConstraintTypes[constraintType]
  if (constraintType === 'Range' && (type === 'LocalDate' || type === 'LocalDateTime')) {
    return [new Date(), new Date()]
  } else {
    return descript.valueCount === 0 ? [] : _.cloneDeep(descript.defalutValues!)
  }
}

/**
 * 取得字段类型支持的字段约束
 * @param type 字段类型
 */
export function getFieldConstraintTypesByFieldType (type: FieldType): FieldConstraintType[] {
  const res: FieldConstraintType[] = []
  for (const key in fieldConstraintTypes) {
    const descript = fieldConstraintTypes[key as FieldConstraintType]

    if (descript.onlyType.findIndex((item) => item === type) >= 0) {
      res.push(key as FieldConstraintType)
    }
  }
  return res
}
