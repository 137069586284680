
import { EnumerationConfig } from '@/api/project/enummanager/enum-config/enum-config'
import enumConfigApi from '@/api/project/enummanager/enum-config/enum-config-api'
import { EnumerationItem } from '@/api/project/enummanager/enum-item/enum-item'
import enumItemApi from '@/api/project/enummanager/enum-item/enum-item-api'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * 枚举信息小组件
 */
@Component({
  name: 'Widget'
})
export default class extends Vue {
  @Prop({
    type: String,
    required: false
  })
  readonly enumUuId?: string

  /** 枚举信息 */
  enumInfo: EnumerationConfig={}
  /** 枚举项信息 */
  enumItemList: EnumerationItem[] = []

  @Watch('enumUuid', { immediate: true })
  onEnumUuidChange () {
    if (!this.enumUuId) return
    enumConfigApi.getItemByUuid(this.enumUuId).then(res => {
      this.enumInfo = res?.data || {}
      if (res.data?.id) {
        return enumItemApi.query({
          page: 1,
          pageSize: 10000,
          sorts: ['+value'],
          enumId: res.data?.id
        })
      }
    }).then(res => {
      this.enumItemList = res?.data?.items || []
    }).catch(err => {
      console.error(err)
      this.$Notice.error({ title: '加载枚举信息失败', desc: err?.message || err })
    })
  }
}
