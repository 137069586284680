import { FieldType } from './field-type-descript'

/**
 * 关于实体jpa注入类型的定义
 */
export type JpaInjectType='Version'|
'CreatedBy'|'LastModifiedBy'|'CreatedDate'|'LastModifiedDate'

/**
 * jpa注入描述
 */
export interface JpaInjectDescript{
    title: string;
    // 限定类型 如果为空不限定
    OnlyType: FieldType[];
}

const jpaInjectTypes: Record<JpaInjectType, JpaInjectDescript> = {

  CreatedBy: {
    title: '创建人ID',
    OnlyType: ['int', 'Integer', 'long', 'Long']
  },
  LastModifiedBy: {
    title: '修改人ID',
    OnlyType: ['int', 'Integer', 'long', 'Long']
  },
  CreatedDate: {
    title: '创建时间',
    OnlyType: ['LocalDateTime']
  },
  LastModifiedDate: {
    title: '修改时间',
    OnlyType: ['LocalDateTime']
  },
  Version: {
    title: '版本字段',
    OnlyType: ['int', 'Integer', 'long', 'Long']
  }
}

export default jpaInjectTypes
