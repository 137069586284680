
import { Prop, Vue, Component } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import EntityModelManger from '@/libs/entitymodel'
import PropertyModal from './property-modal.vue'
import EntityField from '@/libs/entitymodel/entity-field'
import EntityLeftJoin from '@/libs/entitymodel/entity-leftjoin'
import Entity from '@/libs/entitymodel/entity'

/**
 * 字段联结选择框
 */
@Component({
  name: 'fieldLinkModal',
  components: { PropertyModal }
})
export default class FieldLinkModal extends Vue implements BaseModal {
  // 关联实体
  @Prop({
    type: Object,
    required: true
  })
  entity!: Entity

  // 关联的joins
  @Prop({
    type: Array,
    required: true
  })
  joins!: EntityLeftJoin[]

  // 实体模型对象
  @Prop({
    type: Object,
    required: true
  })
  entityModelManger!: EntityModelManger

  /**
   * 当前值
   */
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {
        /// join对应的uuid
        joinUuid: undefined,
        /// link字段的uuid
        linkFieldUuid: undefined
      }
    }
  })
  value!: any

  curValue: any = {}

  /**
   * 表单较验
   */
  rules = {
    joinUuid: [{ required: true, message: '请选择链接实体', trigger: 'blur' }],
    linkFieldUuid: [
      { required: true, message: '请选择链接字段', trigger: 'blur' }
    ]
  }

  /// 实体标题
  getEntityTitle (uuid: string) {
    const joins = this.entity.joins.filter(item => item.uuid === uuid)
    if (joins.length === 0) return `没有找到${uuid}联结对象`
    const join = joins[0]
    const entity = this.entityModelManger.entitiesMap[join.joinEntityUuid]
    const alias = join.joinAlias

    return `${entity.title}(${entity.name})(alias:${alias})`
  }

  /// 当前主实体的字段
  get linkFields () {
    if (!this.curValue.joinUuid) return []

    const joins = this.entity.joins.filter(
      item => item.uuid === this.curValue.joinUuid
    )

    const join = joins[0]
    const entity = this.entityModelManger.entitiesMap[join.joinEntityUuid]

    // 获取实体全部字段，包括父类
    const fields = this.entityModelManger.getEntityAllFieldes(entity.uuid)
    // 过滤掉transient字段
    return fields.filter(f => !f.transient)
  }

  /// 取得实体标题
  getFieldTitle (field: EntityField) {
    return `${field.title}(${field.name})`
  }

  created () {
    this.curValue = this.value
  }

  show (): void {
    ;(this.$refs.dlg as PropertyModal).show()
  }

  close (): void {
    ;(this.$refs.dlg as PropertyModal).close()
  }

  /// 保存处理
  doSave () {
    this.$emit('onOk', this.curValue)
  }

  /// 取消处理
  doCancel () {
    this.$emit('onCancel')
  }
}
