/**
 * 字段自动赋值的方式的定义
 */
export type GenerationType=
'TABLE'|
'SEQUENCE'|
'IDENTITY'|
'AUTO'

/// 自动赋值的描述
export interface GenerationTypeDescript{
  /// 标题
  title: string;
  /// 是否需要生成器
  wantGenerator: boolean;
}

// 可以使用的
const generationTypes: Record<GenerationType, GenerationTypeDescript> = {
  TABLE: {
    title: 'TABLE',
    wantGenerator: false
  },
  SEQUENCE: {
    title: 'SEQUENCE',
    wantGenerator: true
  },
  IDENTITY: {
    title: 'IDENTITY',
    wantGenerator: false
  },
  AUTO: {
    title: 'AUTO',
    wantGenerator: false
  }
}

export default generationTypes
