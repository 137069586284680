import EntityModelManger from '@/libs/entitymodel'
import Entity from '@/libs/entitymodel/entity'
import { showModal } from '@/libs/modal-helper'
import FieldLinkModal from './fieldlink-modal.vue'

export interface FieldLinkData{
    joinUuid: string;
    linkFieldUuid: string;
}

/**
 * 弹出设备字段联结对话框
 * @param joinUuid 当前联结对象
 * @param linkFieldUuid 当前的联结字段uuid
 * @param entity 当前实体
 * @param entityModelManger  当前数据模型
 * @param onOk 确认操作
 */
export default function showFieldLinkModal (joinUuid: string|undefined, linkFieldUuid: string|undefined,
  entity: Entity, entityModelManger: EntityModelManger, onOk?: (data: FieldLinkData) => Promise<void>) {
  showModal<FieldLinkData>(FieldLinkModal, {
    props: {
      entity: entity,
      entityModelManger: entityModelManger,
      value: {
        joinUuid: joinUuid,
        linkFieldUuid: linkFieldUuid
      },
      joins: entity.joins ? entity.joins : []
    }
  }, true, onOk, undefined)
}
